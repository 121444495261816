.standardPopupModalContainer {
  width: 6.38rem;
}

@media (max-width: 768px) {
  .standardPopupModalContainer {
    width: 90.7%;
  }
}

@media (max-width: 768px) {
  .standardPopupModalContainer .standardPopupModalHeader {
    padding: .34rem;
  }
}

.standardPopupModalContainer .standardPopupModalHeader .standardModalTitle {
  margin: auto;
  text-transform: capitalize;
}

.standardPopupModalContainer .standardPopupModalHeader.popUpHeaderWithOneDIV .standardModalTitle {
  display: flex;
  justify-content: center;
  width: 90%;
  padding: .05rem 0;
}

.standardPopupModalContainer .standardPopupModalHeader .iconClose {
  display: flex;
}

.standardPopupModalContainer .standardPopupModalHeader .iconClose svg {
  cursor: pointer;
}

.standardPopupModalContainer .standardPopupModalBody {
  background: #fff;
  padding: .25rem .35rem;
}

@media (max-width: 768px) {
  .standardPopupModalContainer .standardPopupModalBody {
    padding: .55rem .85rem;
  }
}

.standardPopupModalContainer .standardPopupModalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 .25rem;
}

@media (max-width: 768px) {
  .standardPopupModalContainer .standardPopupModalFooter {
    margin: 0 0 .5rem;
  }
}