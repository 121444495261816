.telegram-container {
  margin-bottom: .3rem;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .telegram-container {
    width: auto !important;
  }
}

.telegram-container .telegram-button {
  height: .6rem;
  border: .01rem solid rgba(162,170,189,.2);
  border-radius: .07rem;
  background: rgba(0,0,0,0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 .2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .telegram-container .telegram-button {
    height: 1.3rem;
    padding: .2rem .5rem;
  }
}

.telegram-container .telegram-button svg {
  width: .4rem;
  height: .4rem;
}

@media (max-width: 768px) {
  .telegram-container .telegram-button svg {
    width: .8rem;
    height: .8rem;
  }
}

.telegram-container .telegram-button span {
  margin-left: .1rem;
  font-size: .2rem;
}

@media (max-width: 768px) {
  .telegram-container .telegram-button span {
    font-size: .5rem;
  }
}

.login-telegram-container .telegram-button {
  border: 0.01rem solid #c9c9c9;
  border-radius: .07rem;
  color: #6c6c6c;
}

.TelegramGuidelinePopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7215686275);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.TelegramGuidelinePopupOverlay .TelegramGuidelinePopupContainer {
  width: 4.8rem;
  background: #fff;
  padding: .6rem .67rem;
}

@media (max-width: 768px) {
  .TelegramGuidelinePopupOverlay .TelegramGuidelinePopupContainer {
    width: 10rem;
  }
}

@media (max-width: 768px) {
  .TelegramContent {
    font-size: .4rem;
  }
}

.breakLineContainer {
  margin: 0 auto .3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .breakLineContainer {
    flex-direction: row !important;
  }
}

.breakLineContainer .breakLine {
  height: .01rem;
  background: rgba(162,170,189,.2);
  margin: .15rem 0;
  width: 44%;
}

@media (max-width: 768px) {
  .breakLineContainer .breakLine {
    height: .04rem;
  }
}

.breakLineContainer .breakLineSpan {
  color: #c9c9c9;
  font-size: .2rem;
}

@media (max-width: 768px) {
  .breakLineContainer .breakLineSpan {
    font-size: .6rem;
  }
}

.loginBreakLineContainer {
  width: 60%;
}

@media (max-width: 768px) {
  .loginBreakLineContainer {
    width: 90%;
  }
}

.loginBreakLineContainer .loginBreakLine {
  width: 40%;
  background: rgba(108,108,108,.5);
}

@media (max-width: 768px) {
  .loginBreakLineContainer .loginBreakLine {
    width: 44%;
    height: .01rem;
  }
}

.loginBreakLineContainer .loginBreakLineSpan {
  color: #6c6c6c;
}