.standardFormField {
  position: relative;
  display: grid;
  grid-template-columns: 35% 61%;
  gap: .2rem;
  align-items: center;
  margin: 0 0 .25rem;
}

@media (max-width: 768px) {
  .standardFormField {
    font-size: .42rem;
    gap: .15rem;
    grid-template-columns: auto;
    margin: 0 0 .45rem;
  }
}

.standardFormField.vertical {
  grid-template-columns: unset;
  gap: .06rem .12rem;
  margin-bottom: .24rem;
}

@media (max-width: 768px) {
  .standardFormField.vertical {
    gap: .17rem .23rem;
    margin-bottom: .69rem;
  }
}

.standardFormField .fieldLabel {
  display: block;
}

.standardFormField .errorMessageWrapper {
  font-size: .18rem;
}

.standardFormField .errorMessageWrapper.left-right {
  grid-column: 2/3;
}

@media (max-width: 768px) {
  .standardFormField .errorMessageWrapper {
    font-size: .42rem;
    grid-column: unset;
  }
}

.standardFormField .standardErrorValidation {
  font-size: .18rem;
  color: red;
}

@media (max-width: 768px) {
  .standardFormField .standardErrorValidation {
    font-size: .42rem;
  }
}

.standardFormField .standardErrorValidation.leftRight {
  grid-column: 2/3;
}

.standardFormField .formFieldWrapper {
  display: grid;
  align-items: center;
}

.standardFormField .formFieldWrapper input {
  padding-right: .6rem;
}

@media (max-width: 768px) {
  .standardFormField .formFieldWrapper input {
    padding-right: 1.4rem;
  }
}

.standardFormField .formFieldWrapper .copy {
  position: absolute;
  margin: 0;
  border-radius: 100%;
  padding: .03rem;
  right: .2758rem;
  z-index: 1;
  fill: #fff;
  display: flex;
  cursor: pointer;
  width: .2242rem;
  height: .2822rem;
}

.standardFormField .formFieldWrapper .copy svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .standardFormField .formFieldWrapper .copy {
    right: .3726rem;
    width: .64rem;
    height: .81rem;
  }
}