.passwordRevealIconContainer {
  width: .3rem;
  position: absolute;
  right: .1rem;
  top: .15rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .passwordRevealIconContainer {
    width: .7rem;
    right: .28rem;
    top: .4rem;
  }

  :global(.standard-form-field) .passwordRevealIconContainer {
    top: 1.15rem;
  }
}

.passwordRevealIconContainer .hasLabel {
  top: 1rem;
}

.passwordRevealIconContainer.vertical.hasLabel {
  top: .4rem;
}

@media (max-width: 768px) {
  .passwordRevealIconContainer.vertical.hasLabel {
    top: 1.1rem;
  }
}

.passwordRevealIconContainer img {
  height: 100%;
  width: 100%;
}