.specialForYouContainer {
  padding: .45rem .59rem 1rem .47rem;
  background: #fff;
}

.specialForYouContainer .tabList {
  display: grid;
  grid-gap: .15rem;
  grid-template-columns: 21% 21% 21%;
  padding: 0 0 .03rem;
  margin: 0 0 .3rem;
  border: .01rem #eee;
  border-style: none none solid;
  color: #fff;
}

@media (max-width: 1024px) {
  .specialForYouContainer .tabList {
    grid-template-columns: auto auto auto;
    grid-gap: .4rem;
    overflow: scroll;
  }
}

.specialForYouContainer .tabList .tabHeader {
  font-size: .14rem;
  align-items: center;
  background: #cdcdcd;
  border-radius: .1rem .1rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: .3rem;
  cursor: pointer;
}

.specialForYouContainer .tabList .tabHeader.active {
  background: #ff4e00;
}

@media (max-width: 1024px) {
  .specialForYouContainer .tabList .tabHeader {
    height: 1.2rem;
    width: 5rem;
    font-size: .34rem;
    border-radius: .3rem .3rem 0 0;
  }
}

.specialForYouContainer .noPromotionYet {
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: .6rem;
}

.specialForYouContainer .unclaimedContainer {
  position: relative;
  border-radius: 0.1rem;
  background: #dedede;
}

.specialForYouContainer .unclaimedContainer .newIcon {
  position: absolute;
  top: 0;
  width: .55rem;
}

@media (max-width: 1024px) {
  .specialForYouContainer .unclaimedContainer .newIcon {
    width: 1rem;
  }
}

.specialForYouContainer .unclaimedContainer:nth-child(even) {
  background: #f3f1f2;
}

.specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer {
  font-weight: 700;
  height: .65rem;
  padding: .14rem .35rem;
  margin: .18rem 0;
  display: grid;
  grid-template-columns: 80% auto;
  justify-content: space-between;
  align-items: center;
}

.specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer:hover {
  border: 0.01rem solid #ff4e00;
  border-radius: .1rem;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer {
    height: 1.65rem;
    border-radius: .3rem;
    padding: .14rem .6rem;
  }
}

.specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer .unclaimedName {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: .16rem;
  color: #ff4e00;
  cursor: pointer;
  grid-row: 1/span 1;
}

@media (max-width: 1024px) {
  .specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer .unclaimedName {
    font-size: .4rem;
  }
}

.specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer .remainingTimeContainer {
  font-size: .12rem;
  display: flex;
  grid-row: 2/span 1;
}

@media (max-width: 1024px) {
  .specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer .remainingTimeContainer {
    font-size: .28rem;
  }
}

.specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer .remainingTimeContainer .remaningTime {
  color: #4c4c4c;
}

.specialForYouContainer .unclaimedContainer .unclaimedDetailsContainer .remainingTimeContainer .remaningCountdown {
  color: #ff4e00;
}

.specialForYouContainer .unclaimedContainer button {
  width: 1.19rem;
  height: .32rem;
  border-radius: 0.05rem;
  background: #ff4c00;
  color: #fff;
  border: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .specialForYouContainer .unclaimedContainer button {
    width: 2rem;
    height: 1rem;
    border-radius: .25rem;
  }
}

.specialForYouContainer .unclaimedContainer button:disabled {
  background: #ffa67f;
  cursor: not-allowed;
}

.specialForYouContainer .paginationContainer {
  display: flex;
  justify-content: space-between;
  color: #939393;
  font-size: .14rem;
}

@media (max-width: 1024px) {
  .specialForYouContainer .paginationContainer {
    font-size: .35rem;
  }
}

.specialForYouContainer .paginationContainer .paginationPage button {
  padding: .08rem .1rem;
  background: rgba(0,0,0,0);
  font-size: .14rem;
  border: 0.01rem solid rgba(0,0,0,.25);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .specialForYouContainer .paginationContainer .paginationPage button {
    font-size: .35rem;
  }
}

.promosDetailPopup .popup {
  max-width: 90%;
  max-height: 90%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.promosDetailPopup .popupHeader {
  background: #ff4e00;
}

.promosDetailPopup .popupHeader .popupHeaderRight svg {
  fill: #ff4e00;
}

.promosDetailPopup .promo {
  width: 100%;
}

.promosDetailPopup .promo .promoTitle {
  color: #2f2f2f;
}

.promosDetailPopup .promo .promoInput {
  background: #f2f2f2;
  border-radius: .09rem;
  height: .4rem;
  align-items: center;
  display: flex;
  padding: 0 .22rem;
  color: #2f2f2f;
}

@media (max-width: 1024px) {
  .promosDetailPopup .promo .promoInput {
    height: .7rem;
  }
}

@media (max-width: 768px) {
  .promosDetailPopup .promo .promoInput {
    height: 1rem;
    border-radius: .2rem;
  }
}

.promosDetailPopup .promo .promoInput span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  text-decoration: none;
}

.promosDetailPopup .buttonContainer {
  gap: .2rem;
}

@media (max-width: 1024px) {
  .promosDetailPopup .buttonContainer {
    display: grid;
    grid-template-columns: 49% 49%;
  }
}

.promosDetailPopup .buttonContainer .submitButton {
  background: #ff4e00 !important;
}

@media (max-width: 1024px) {
  .promosDetailPopup .buttonContainer .submitButton {
    cursor: pointer;
    height: 1.27rem;
    border-radius: .13rem;
    border-style: none;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.promosDetailPopup .buttonContainer .cancelButton {
  background: #cdcdcd;
}

@media (max-width: 1024px) {
  .promosDetailPopup .buttonContainer .cancelButton {
    cursor: pointer;
    height: 1.27rem;
    border-radius: .13rem;
    border-style: none;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.promosDetailPopup .popupContentBody {
  min-height: .75rem;
  width: 100%;
  display: grid;
  font-size: .2rem;
  color: #787878;
  background: #fff;
  padding: .4rem .3rem;
  overflow-y: scroll;
}

@media (max-width: 1024px) {
  .promosDetailPopup .popupContentBody {
    font-size: .4rem;
    padding: .4rem .5rem;
  }
}

.promosDetailPopup .popupContentBody .popupContent {
  padding: 0 .65rem .4rem;
  display: grid;
  row-gap: .2rem;
}

.promosDetailPopup .popupContentBody .popupContent .popupContentDetails {
  display: grid;
  grid-template-columns: 35% 20% 30%;
}

.promosDetailPopup .popupContentBody .popupContentProvider {
  display: grid;
  grid-template-columns: 45% auto;
  border-radius: 0.1rem;
  background: #e5e5e5;
  padding: .2rem .35rem;
  color: #2f2f2f;
}

.promosDetailPopup .popupContentBody .popupContentProvider .productTitle,
.promosDetailPopup .popupContentBody .popupContentProvider .providerTitle {
  border-bottom: .01rem solid;
  display: flex;
  align-items: center;
  font-weight: 700;
}

@media (max-width: 1024px) {
  .promosDetailPopup .popupContentBody .popupContentProvider .productTitle,
  .promosDetailPopup .popupContentBody .popupContentProvider .providerTitle {
    border-bottom: 0;
  }
}

.promosDetailPopup .popupContentBody .popupContentProvider .productContent,
.promosDetailPopup .popupContentBody .popupContentProvider .providerContent {
  display: grid;
  padding: .1rem 0;
}

@media (max-width: 1024px) {
  .promosDetailPopup .popupContentBody .popupContentProvider {
    display: flex;
    flex-direction: column;
  }

  .promosDetailPopup .popupContentBody .popupContentProvider .providerContentContainer {
    border-bottom: .01rem solid;
    padding: .3rem 0;
  }
}

.promosDetailPopup .popupContentBody .tncText {
  font-size: .12rem;
}

@media (max-width: 1024px) {
  .promosDetailPopup .popupContentBody .tncText {
    font-size: .24rem;
  }
}