.userVerifications {
  background: #d9d9d9;
  border-radius: .05rem;
  padding: .12rem .14rem .16rem;
}

@media (max-width: 768px) {
  .userVerifications {
    display: grid;
    grid-template-columns: 35% auto;
    align-items: center;
    padding: .3rem .5rem;
    gap: .4rem;
  }
}

.verificationIcons {
  display: flex;
}

.verifyIcon {
  height: .39rem;
  width: .39rem;
  background: #ababab;
  border-radius: 50%;
  display: flex;
  padding: .06rem;
  margin-right: .05rem;
  cursor: pointer;
}

.verifyIcon:last-child {
  margin-right: 0;
}

.verifyIcon.active {
  background: #ff4e00;
}

.verifyIcon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .verifyIcon {
    width: 1rem;
    height: 1rem;
    padding: .15rem;
    margin-right: .4rem;
  }
}