.otpField .otpFieldLabel span {
  color: #ff4e00;
}

.otpField .otpFieldWrapper {
  width: 100%;
  display: grid;
  gap: 4.062%;
}

.otpField .otpFieldWrapper.withOtpButton {
  grid-template-columns: 67.54% 28.398%;
}

.otpField .otpButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-template-columns: unset;
  height: 100%;
}

.otpField .otpButtonContainer .otpButton {
  width: 100%;
  height: 100%;
  border-radius: .05rem;
}

.otpField .otpButtonContainer .otpButton:disabled {
  opacity: .5;
}