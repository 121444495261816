.promosDetailPopup .popup {
  max-width: 90%;
  max-height: 90%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.promosDetailPopup .popupHeader {
  background: #ff4e00;
}

.promosDetailPopup .popupHeader .popupHeaderRight svg {
  fill: #ff4e00;
}

.promosDetailPopup .promo {
  width: 100%;
}

.promosDetailPopup .promo .promoTitle {
  color: #2f2f2f;
}

.promosDetailPopup .promo .promoInput {
  background: #f2f2f2;
  border-radius: .09rem;
  height: .4rem;
  align-items: center;
  display: flex;
  padding: 0 .22rem;
  color: #2f2f2f;
}

@media (max-width: 1024px) {
  .promosDetailPopup .promo .promoInput {
    height: 1.27rem;
    border-radius: .29rem;
  }
}

@media (max-width: 768px) {
  .promosDetailPopup .promo .promoInput {
    height: 1rem;
    border-radius: .2rem;
  }
}

.promosDetailPopup .promo .promoInput span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  text-decoration: none;
}

.promosDetailPopup .buttonContainer {
  gap: .2rem;
}

@media (max-width: 768px) {
  .promosDetailPopup .buttonContainer {
    display: grid;
    grid-template-columns: 49% 49%;
  }
}

.promosDetailPopup .buttonContainer .submitButton {
  background: #ff4e00 !important;
}

@media (max-width: 1024px) {
  .promosDetailPopup .buttonContainer .submitButton {
    cursor: pointer;
    height: 1.27rem;
    border-radius: .13rem;
    border-style: none;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: .4rem;
  }
}

.promosDetailPopup .buttonContainer .cancelButton {
  background: #cdcdcd;
}

@media (max-width: 1024px) {
  .promosDetailPopup .buttonContainer .cancelButton {
    cursor: pointer;
    height: 1.27rem;
    border-radius: .13rem;
    border-style: none;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: .4rem;
  }
}

.promosDetailPopup .popupContentBody {
  min-height: .75rem;
  width: 100%;
  display: grid;
  font-size: .2rem;
  color: #787878;
  background: #fff;
  padding: .4rem .3rem;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .promosDetailPopup .popupContentBody {
    font-size: .4rem;
    padding: .4rem .5rem;
  }
}

.promosDetailPopup .popupContentBody .popupContent {
  padding: 0 .65rem .4rem;
  display: grid;
  row-gap: .2rem;
}

.promosDetailPopup .popupContentBody .popupContent .popupContentDetails {
  display: grid;
  grid-template-columns: 35% 20% 30%;
}

.promosDetailPopup .popupContentBody .popupContentProvider {
  display: grid;
  grid-template-columns: 45% auto;
  border-radius: 0.1rem;
  background: #e5e5e5;
  padding: .2rem .35rem;
  color: #2f2f2f;
}

.promosDetailPopup .popupContentBody .popupContentProvider .productTitle,
.promosDetailPopup .popupContentBody .popupContentProvider .providerTitle {
  border-bottom: .01rem solid;
  display: flex;
  align-items: center;
  font-weight: 700;
}

@media (max-width: 768px) {
  .promosDetailPopup .popupContentBody .popupContentProvider .productTitle,
  .promosDetailPopup .popupContentBody .popupContentProvider .providerTitle {
    border-bottom: 0;
  }
}

.promosDetailPopup .popupContentBody .popupContentProvider .productContent,
.promosDetailPopup .popupContentBody .popupContentProvider .providerContent {
  display: grid;
  padding: .1rem 0;
}

@media (max-width: 768px) {
  .promosDetailPopup .popupContentBody .popupContentProvider {
    display: flex;
    flex-direction: column;
  }

  .promosDetailPopup .popupContentBody .popupContentProvider .providerContentContainer {
    border-bottom: .01rem solid;
    padding: .3rem 0;
  }
}

.promosDetailPopup .popupContentBody .tncText {
  font-size: .12rem;
}

@media (max-width: 768px) {
  .promosDetailPopup .popupContentBody .tncText {
    font-size: .24rem;
  }
}