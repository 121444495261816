.buttonWrapper {
  background: none;
  border: none;
}

.svgIcon {
  display: flex;
  cursor: pointer;
}

.svgIcon.iconHamburger {
  width: .32rem;
  height: .23rem;
}