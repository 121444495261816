.Input_input {
  border-radius: .09rem;
  border: .01rem solid rgba(25,25,25,.2);
  color: #000;
  min-height: .48rem;
  height: 100%;
  width: 100%;
  padding: 0 .22rem;
}

@media (max-width: 768px) {
  .Input_input {
    height: 1.27rem;
  }
}

.Input_input::placeholder {
  font-size: .18rem;
}

@media (max-width: 768px) {
  .Input_input::placeholder {
    font-size: .42rem;
  }
}