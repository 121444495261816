.passwordRulesMsgWrapper {
  display: flex;
  align-items: center;
  gap: .06rem;
  margin: .04rem .02rem .04rem 0rem;
}

@media (max-width: 768px) {
  .passwordRulesMsgWrapper {
    margin: .078rem .078rem .078rem 0rem;
  }
}

.passwordRulesMsgWrapper .errorMessageText {
  margin: auto auto 0 .02rem;
  flex: 1;
  color: #8697a2;
}

.passwordGuideMessage {
  color: #8697a2;
  font-size: .12rem;
}

@media (max-width: 768px) {
  .passwordGuideMessage {
    font-size: .236rem;
  }
}

.statusIcon {
  width: .12rem;
  height: .12rem;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .statusIcon {
    width: .236rem;
    height: .236rem;
  }
}