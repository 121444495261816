.setupPinPopupOverlay {
  display: unset !important;
  align-items: unset !important;
}

.setupPinPopupOverlay .popupInnerContainer .setupPinPopup {
  width: 5.81rem;
}

@media (max-width: 768px) {
  .setupPinPopupOverlay .popupInnerContainer .setupPinPopup {
    border-radius: .28rem;
    width: 90.7%;
  }
}

@media (max-width: 768px) {
  .setupPinPopupOverlay .popupInnerContainer .setupPinPopupHeader {
    padding: .34rem;
  }
}

.setupPinPopupOverlay .popupInnerContainer .setupPinPopupBody {
  padding: .2rem .44rem .35rem;
}

@media (max-width: 768px) {
  .setupPinPopupOverlay .popupInnerContainer .setupPinPopupBody {
    padding: .98rem;
  }
}

.setupNoticeContainer {
  display: flex;
  align-items: center;
  margin: .2rem 0;
}

.setupNoticeContainer .iconWarning {
  width: .2rem;
  height: .2rem;
  margin-right: .17rem;
  display: flex;
}

.setupNoticeContainer .iconWarning svg {
  width: .2rem;
  height: .2rem;
}

@media (max-width: 768px) {
  .setupNoticeContainer .iconWarning {
    width: .52rem;
    height: .52rem;
    margin-right: .4rem;
  }

  .setupNoticeContainer .iconWarning svg {
    width: .52rem;
    height: .52rem;
  }
}

.setupNoticeContainer span {
  color: #0071e3;
  cursor: pointer;
}

.breakPoint {
  margin: .48rem -0.15rem .38rem;
  border: .01rem solid rgba(142,142,142,.3);
}

@media (max-width: 768px) {
  .breakPoint {
    margin: 1.15rem 0;
  }
}

.btnActionContainerSection {
  margin-top: .76rem;
}

@media (max-width: 768px) {
  .btnActionContainerSection {
    margin-top: 2.19rem;
  }
}

.btnActionContainer {
  display: grid;
  grid-template-columns: 1.76rem 1.76rem;
  height: .48rem;
  gap: .19rem;
  justify-content: flex-end;
}

.btnActionContainer button {
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .btnActionContainer {
    height: 1.27rem;
    grid-template-columns: 4.42rem 4.42rem;
    gap: .4rem;
  }
}

.authOtpTabsSection {
  margin-bottom: .3695rem;
}

@media (max-width: 768px) {
  .authOtpTabsSection {
    margin-bottom: .92rem;
  }
}

.formField {
  display: grid;
  gap: .06rem .12rem;
  margin-bottom: .24rem;
}

@media (max-width: 768px) {
  .formField {
    gap: .17rem .23rem;
    margin-bottom: .69rem;
  }
}

.formField input {
  background: #f2f2f2;
}