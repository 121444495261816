.menuItem {
  display: flex;
  align-items: center;
}

.menuItem svg {
  width: .3rem;
  height: .3rem;
}

.menuItem.left {
  margin-bottom: .25rem;
}

.menuItem.left svg {
  margin-right: .25rem;
}

.menuItem.on,
.menuItem:hover {
  cursor: pointer;
}

.menuItem.on[data-page=home],
.menuItem.on[data-page=sportsbook],
.menuItem.on[data-page=esportsbook],
.menuItem.on[data-page=casino],
.menuItem.on[data-page=slot],
.menuItem.on[data-page=fishing],
.menuItem.on[data-page=fourd],
.menuItem.on[data-page=lottery],
.menuItem.on[data-page=fastgames],
.menuItem.on[data-page=sponsors],
.menuItem.on[data-page=promotions],
.menuItem.on[data-page=vip],
.menuItem.on[data-page=leaderboard],
.menuItem.on[data-page=cockfight],
.menuItem:hover[data-page=home],
.menuItem:hover[data-page=sportsbook],
.menuItem:hover[data-page=esportsbook],
.menuItem:hover[data-page=casino],
.menuItem:hover[data-page=slot],
.menuItem:hover[data-page=fishing],
.menuItem:hover[data-page=fourd],
.menuItem:hover[data-page=lottery],
.menuItem:hover[data-page=fastgames],
.menuItem:hover[data-page=sponsors],
.menuItem:hover[data-page=promotions],
.menuItem:hover[data-page=vip],
.menuItem:hover[data-page=leaderboard],
.menuItem:hover[data-page=cockfight] {
  color: #ff4e00;
}

.menuItem.on[data-page=home]::before,
.menuItem.on[data-page=sportsbook]::before,
.menuItem.on[data-page=esportsbook]::before,
.menuItem.on[data-page=casino]::before,
.menuItem.on[data-page=slot]::before,
.menuItem.on[data-page=fishing]::before,
.menuItem.on[data-page=fourd]::before,
.menuItem.on[data-page=lottery]::before,
.menuItem.on[data-page=fastgames]::before,
.menuItem.on[data-page=sponsors]::before,
.menuItem.on[data-page=promotions]::before,
.menuItem.on[data-page=vip]::before,
.menuItem.on[data-page=leaderboard]::before,
.menuItem.on[data-page=cockfight]::before,
.menuItem:hover[data-page=home]::before,
.menuItem:hover[data-page=sportsbook]::before,
.menuItem:hover[data-page=esportsbook]::before,
.menuItem:hover[data-page=casino]::before,
.menuItem:hover[data-page=slot]::before,
.menuItem:hover[data-page=fishing]::before,
.menuItem:hover[data-page=fourd]::before,
.menuItem:hover[data-page=lottery]::before,
.menuItem:hover[data-page=fastgames]::before,
.menuItem:hover[data-page=sponsors]::before,
.menuItem:hover[data-page=promotions]::before,
.menuItem:hover[data-page=vip]::before,
.menuItem:hover[data-page=leaderboard]::before,
.menuItem:hover[data-page=cockfight]::before {
  color: #ff4e00;
}