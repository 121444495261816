.cryptoAddressPopupOverlay {
  display: unset !important;
  align-items: unset !important;
}

.cryptoAddressPopupOverlay .popupInnerContainer .cryptoAddressPopup {
  width: 5.81rem;
}

@media (max-width: 768px) {
  .cryptoAddressPopupOverlay .popupInnerContainer .cryptoAddressPopup {
    border-radius: .28rem;
    width: 90.7%;
  }
}

@media (max-width: 768px) {
  .cryptoAddressPopupOverlay .popupInnerContainer .cryptoAddressPopupHeader {
    padding: .34rem;
  }
}

.cryptoAddressPopupOverlay .popupInnerContainer .cryptoAddressPopupBody {
  padding: .2rem .44rem .35rem;
}

@media (max-width: 768px) {
  .cryptoAddressPopupOverlay .popupInnerContainer .cryptoAddressPopupBody {
    padding: .98rem;
  }
}

.cryptoAddressPopup .formField {
  gap: .06rem .12rem;
  margin-bottom: .24rem;
}

@media (max-width: 768px) {
  .cryptoAddressPopup .formField {
    gap: .17rem .23rem;
    margin-bottom: .69rem;
  }
}

.cryptoAddressPopup .formField input {
  background: #f2f2f2;
}

.cryptoAddressPopup .formInput,
.cryptoAddressPopup .formSelect {
  min-height: .48rem;
  height: .48rem;
}

@media (max-width: 768px) {
  .cryptoAddressPopup .formInput,
  .cryptoAddressPopup .formSelect {
    min-height: 1.27rem;
    height: 1.27rem;
  }
}

.cryptoAddressPopup .submitBtnSection {
  margin-top: .76rem;
}

@media (max-width: 768px) {
  .cryptoAddressPopup .submitBtnSection {
    margin-top: .92rem;
  }
}

.cryptoAddressPopup .submitBtnSection .submitBtnContainer {
  height: .48rem;
}

@media (max-width: 768px) {
  .cryptoAddressPopup .submitBtnSection .submitBtnContainer {
    height: 1.27rem;
  }
}