.button:hover {
  cursor: pointer;
}

.button:disabled {
  cursor: unset;
}

.largeAuthButton {
  width: 1.8rem;
  border: .05rem;
  border: .01rem solid #fff;
  border-radius: .05rem;
  background: linear-gradient(180deg, #292B2E 0%, rgba(17, 17, 17, 0) 126.7%);
  padding: .08rem 0 .07rem;
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .09rem;
  border: .01rem solid #ff4e00;
  background: #ff4e00;
  color: #fff;
  font-size: .18rem;
  height: .5rem;
  padding: .1rem .5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .submitButton {
    font-size: .42rem;
    height: 1.2rem;
    width: 5rem;
  }
}